<template>
  <div class="catalog">
    <div class="title-div" :class="$mq" style="background: var(--primary-color);">
      <h1 class="title" :class="$mq">Katalog</h1>
    </div>
    
    <div class="divider divider-waves" :class="$mq"></div>

    <br>

    <b-card-group id="card-group" class="xcontainer" deck>
        <b-card class="card" @click="$router.push('/catalog/machines')">
          <b-row class="row">
            <b-card-img class="card-img" src="@/assets/images/machines.jpg"></b-card-img>
          </b-row>
          <b-row>
            <b-card-body title="Maskiner" style="margin: 0.8rem;">
              <b-card-text></b-card-text>
            </b-card-body>
          </b-row>
        </b-card>

        <b-card class="card" @click="$router.push('/catalog/vehicles')">
          <b-row class="row">
            <b-card-img class="card-img" src="@/assets/images/vehicles.jpg"></b-card-img>
          </b-row>
          <b-row>
            <b-card-body title="Køretøjer" style="margin: 0.8rem;">
              <b-card-text></b-card-text>
            </b-card-body>
          </b-row>
        </b-card>

        <b-card class="card" @click="$router.push('/catalog/windows_and_doors')">
          <b-row class="row">
            <b-card-img class="card-img" src="@/assets/images/windows_and_doors.jpg"></b-card-img>
          </b-row>
          <b-row>
            <b-card-body title="Vinduer og døre" style="margin: 0.8rem;">
              <b-card-text></b-card-text>
            </b-card-body>
          </b-row>
        </b-card>

        <b-card class="card" @click="$router.push('/catalog/tools')">
          <b-row class="row">
            <b-card-img class="card-img" src="@/assets/images/tools.jpg"></b-card-img>
          </b-row>
          <b-row>
            <b-card-body title="Værktøj" style="margin: 0.8rem;">
              <b-card-text></b-card-text>
            </b-card-body>
          </b-row>
        </b-card>

        <b-card class="card" @click="$router.push('/catalog/furniture')">
          <b-row class="row">
            <b-card-img class="card-img" src="@/assets/images/furniture.jpg"></b-card-img>
          </b-row>
          <b-row>
            <b-card-body title="Møbler" style="margin: 0.8rem;">
              <b-card-text></b-card-text>
            </b-card-body>
          </b-row>
        </b-card>

        <b-card class="card" @click="$router.push('/catalog/miscellaneous')">
          <b-row class="row">
            <b-card-img class="card-img" src="@/assets/images/miscellaneous.jpg" style="transform: translateY(-4em);"></b-card-img>
          </b-row>
          <b-row>
            <b-card-body title="Diverse" style="margin: 0.8rem;">
              <b-card-text></b-card-text>
            </b-card-body>
          </b-row>
        </b-card>

    </b-card-group>
  
  </div>
</template>

<script>
    export default {
        name: 'Catalog'
    }
</script>

<style lang="scss">
  .catalog {
    background: var(--light-primary-color);
  }

  .title-div {
    color: var(--light-primary-color);
  }

  .title {
    padding-top: 3.5em;
    padding-bottom: 1em;
    display: block;
    text-align: center;
    margin: 0;
    font-family: 'Red Hat Text', sans-serif;
    font-weight: 500;
  }

  .title.desktop {
    font-size: 2.6rem;
  }

  .paginated-cardgroup > #card-group {
    margin: auto;
  }

  .card {
    max-width: 240px;
    min-width: 240px;
    height: 23rem;
    overflow: hidden;
    cursor: pointer;
  }

  .card-img {
    object-fit: cover;
  }

  .row {
    margin: 0;
    height: 200px;
    overflow: hidden;
  }

  .card-body {
    padding: 0;
  }

  #card-group {
    padding-bottom: 7em;
    margin: 0;
    flex-flow: wrap;
  }
</style>